import { useParams } from "react-router-dom";
import { FloatingMenu } from "../floating_menu/FloatingMenu";
import styles from "./ViewPort.module.css";

export const ViewPort = () => {
  const currentEnv =
    process.env.REACT_APP_ENV === undefined ? "dev" : process.env.REACT_APP_ENV;
  const frameSrc = `https://connector_dev1.eaglepixelstreaming.com/v5/wspinternal/Experience_dev/${currentEnv}`;
  const { projectName, orgName } = useParams();
  console.log(`Current Project -> ${projectName}`);
  console.log(`Current Organization -> ${orgName}`);
  return (
    <div className={styles["view-port"]}>
      <iframe
        id="iframe"
        style={{ overflow: "hidden", border: "none" }}
        title="Format Flow"
        src={frameSrc}
        scrolling="no"
        width="100%"
        height="100%"
        allowFullScreen
      ></iframe>
      <div>
        <FloatingMenu onUploadPage={false} />
      </div>
    </div>
  );
};

